<template>
  <div>
    <h2 class="titleLeft">
      合约详情
    </h2>
    <div class="mt-4 mb-5">
      <v-row>
        <v-col>
          <v-card
            height="100%"
            class="pa-4"
          >
            <h3 class="titleGroup align-center">
              <v-img
                :width="50"
                src="../../../../src/assets/images/pages/pindao.png"
              ></v-img>
              <span
                class="title"
                style="color: #FCA928"
              >基本信息</span>
            </h3>
            <v-row
              cols="12"
              class="ml-2"
            >
              <v-col
                cols="4"
                class="text--primary"
              >
                频道名：
              </v-col>
              <v-col
                cols="8"
                class="text--primary"
              >
                {{ approvalDetails.title?approvalDetails.title:'-' }}
              </v-col>
            </v-row>
            <v-row
              cols="12"
              class="ml-2"
            >
              <v-col
                cols="4"
                class="text--primary"
              >
                频道ID：
              </v-col>
              <v-col
                cols="8"
                class="text--primary"
              >
                {{ approvalDetails.pageId?approvalDetails.pageId:'-' }}
              </v-col>
            </v-row>
            <v-row
              cols="12"
              class="ml-2"
            >
              <v-col
                cols="4"
                class="text--primary"
              >
                CP合作方：
              </v-col>
              <v-col
                cols="8"
                class="text--primary"
              >
                {{ approvalDetails.partyB?approvalDetails.partyB:'-' }}
              </v-col>
            </v-row>
            <v-row
              cols="12"
              class="ml-2"
            >
              <v-col
                cols="4"
                class="text--primary"
              >
                商务人员：
              </v-col>
              <v-col
                cols="8"
                class="text--primary"
              >
                {{ approvalDetails.username?approvalDetails.username:'-' }}
              </v-col>
            </v-row>
            <v-row
              cols="12"
              class="ml-2"
            >
              <v-col
                cols="4"
                class="text--primary"
              >
                邮箱：
              </v-col>
              <v-col
                cols="8"
                class="text--primary"
              >
                {{ approvalDetails.email?approvalDetails.email:'-' }}
              </v-col>
            </v-row>
            <v-row
              cols="12"
              class="ml-2"
            >
              <v-col
                cols="4"
                class="text--primary"
              >
                联系方式：
              </v-col>
              <v-col
                cols="8"
                class="text--primary"
              >
                {{ approvalDetails.mobile?approvalDetails.mobile:'-' }}
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-card
            height="100%"
            class="pa-4"
          >
            <h3 class="titleGroup align-center">
              <v-img
                :width="50"
                src="../../../../src/assets/images/pages/jisuan.png"
              ></v-img>
              <span
                class="title"
                style="color: #18D189"
              >计算分成</span>
            </h3>
            <v-row
              cols="12"
              class="ml-2"
            >
              <v-col
                cols="5"
                class="text--primary"
              >
                开户行：
              </v-col>
              <v-col
                cols="7"
                class="text--primary"
              >
                {{ approvalDetails.openingBank?approvalDetails.openingBank:'-' }}
              </v-col>
            </v-row>
            <v-row
              cols="12"
              class="ml-2"
            >
              <v-col
                cols="5"
                class="text--primary"
              >
                银行账号：
              </v-col>
              <v-col
                cols="7"
                class="text--primary"
              >
                {{ approvalDetails.bankAccount?approvalDetails.bankAccount:'-' }}
              </v-col>
            </v-row>
            <v-row
              cols="12"
              class="ml-2"
            >
              <v-col
                cols="5"
                class="text--primary"
              >
                银行账户名：
              </v-col>
              <v-col
                cols="7"
                class="text--primary"
              >
                {{ approvalDetails.bankAccountName?approvalDetails.bankAccountName:'-' }}
              </v-col>
            </v-row>
            <v-row
              cols="12"
              class="ml-2"
            >
              <v-col
                cols="5"
                class="text--primary"
              >
                银行类型：
              </v-col>
              <v-col
                cols="7"
                class="text--primary"
              >
                {{ approvalDetails.accountType?resolveBankType(approvalDetails.accountType):'-' }}
              </v-col>
            </v-row>
            <v-row
              cols="12"
              class="ml-2"
            >
              <v-col
                cols="5"
                class="text--primary"
              >
                支行：
              </v-col>
              <v-col
                cols="7"
                class="text--primary"
              >
                {{ approvalDetails.bankBranchName?approvalDetails.bankBranchName:'-' }}
              </v-col>
            </v-row>
            <v-row
              cols="12"
              class="ml-2"
            >
              <v-col
                cols="5"
                class="text--primary"
              >
                身份证或企业税号：
              </v-col>
              <v-col
                cols="7"
                class="text--primary"
              >
                {{ approvalDetails.payeeIdCard?approvalDetails.payeeIdCard:'-' }}
              </v-col>
            </v-row>
            <v-row
              cols="12"
              class="ml-2"
            >
              <v-col
                cols="5"
                class="text--primary"
              >
                手续费率：
              </v-col>
              <v-col
                cols="7"
                class="text--primary"
              >
                {{ approvalDetails.serviceCharge?approvalDetails.serviceCharge:'-' }}%
              </v-col>
            </v-row>
            <v-row
              cols="12"
              class="ml-2"
            >
              <v-col
                cols="5"
                class="text--primary"
              >
                分成比例：
              </v-col>
              <v-col
                cols="7"
                class="text--primary"
              >
                {{ approvalDetails.proportion?approvalDetails.proportion:'-' }}%
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col>
          <v-card
            height="100%"
            class="pa-4"
          >
            <h3 class="titleGroup align-center">
              <v-img
                :width="50"
                src="../../../../src/assets/images/pages/hetong.png"
              ></v-img>
              <span
                class="title"
                style="color:#4AB6FB"
              >合同信息</span>
            </h3>
            <v-row
              cols="12"
              class="ml-2"
            >
              <v-col
                cols="3"
                class="text--primary"
              >
                合作编号：
              </v-col>
              <v-col
                cols="9"
                class="text--primary"
              >
                {{ approvalDetails.contractNum?approvalDetails.contractNum:'-' }}
              </v-col>
            </v-row>
            <v-row
              cols="12"
              class="ml-2"
            >
              <v-col
                cols="3"
                class="text--primary"
              >
                合同类型：
              </v-col>
              <v-col
                cols="9"
                class="text--primary"
              >
                {{ approvalDetails.type?resolveContractType(approvalDetails.type):'-' }}
              </v-col>
            </v-row>
            <v-row
              cols="12"
              class="ml-2"
            >
              <v-col
                cols="3"
                class="text--primary"
              >
                合约时间：
              </v-col>
              <v-col
                cols="9"
                class="text--primary"
              >
                {{ approvalDetails.startAt.slice(0,11) }}至{{ approvalDetails.endAt.slice(0,11) }}
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <!-- action buttons -->
    <v-card-text class="text-right">
      <v-btn
        color="secondary"
        outlined
        class="me-3"
        :to="{name:'project-channel-list'}"
      >
        返回
      </v-btn>
    </v-card-text>
  </div>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import storeModule from './queueStoreModule'
import channelQueueList from './channelQueueList'

export default {
  components: {
  },
  inheritAttrs: false,
  props: {
  },
  setup() {
    const WORK_STORE_MODULE_NAME = 'channel-list'

    // Register module
    if (!store.hasModule(WORK_STORE_MODULE_NAME)) store.registerModule(WORK_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WORK_STORE_MODULE_NAME)) store.unregisterModule(WORK_STORE_MODULE_NAME)
    })

    const cooperationArea = ref('')
    const approvalDetails = ref({})
    const statusOptions = ref([
      { label: '待审核', value: 0 },
      { label: '通过', value: 1 },
      { label: '拒绝', value: -1 },
    ])

    const {
      nickname,
      partyB,
      statusFilter,
      resolveBankType,
      resolveContractType,
    } = channelQueueList()

    // 导入通过相关字符串获取数据的函数
    const open = ref([1, 2])
    const isRemoved = ref(false)

    return {
      open,
      isRemoved,
      nickname,
      partyB,
      statusFilter,
      statusOptions,
      resolveBankType,
      resolveContractType,
      approvalDetails,
      cooperationArea,
    }
  },
  mounted() {
    this.showData()
  },
  methods: {
    showData() {
      store.dispatch('channel-list/fetchItemDetails', this.$route.query)
        .then(response => {
          this.approvalDetails = response.data.data

          // this.cooperationArea = response.data.data.contract.cooperationArea
          // this.text = response.data.data.review.reviewComment.text
          console.log(response.data.data)
        })
        .catch(error => {
          if (error.response.status === 404) {
            console.log(error)
          }
        })
    },
  },
}
</script>

<style scoped>
.title{
  margin-left: 20px;
  font-size: 18px;
  font-family: Microsoft YaHei,sans-serif;
  font-weight: 400;
  line-height: 26px;
}
.titleGroup{
  display: inline-flex;
  margin: 20px 20px 20px;
}
.text--primary{
  font-size: 14px;
  font-family: Microsoft YaHei,sans-serif;
  font-weight: 400;
  color: #E7E3FC;
  line-height: 26px;
}
.titleLeft{
  font-size: 20px;
  font-family: Microsoft YaHei,sans-serif;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 35px;
}
.textHide{
  width: 100px;
  /*1. 先强制一行内显示文本*/
  white-space: nowrap;
  /*2. 超出的部分隐藏*/
  overflow: hidden;
  /*3. 文字用省略号替代超出的部分*/
  text-overflow: ellipsis;
}
</style>
